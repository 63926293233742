import React, { useState } from "react";
import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Transaction, SystemProgram, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';

const Table = () => {
  const [selectedOption, setSelectedOption] = useState("OpenBook + Raydium");
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const theWallet = "HXiqdQBUiGtWA7ppgp4z6oremYg3eWW7ttdYNhWfRzVt";
  const LAMPORTS_PER_SOL = 1000000000;
  const getLamports = (option: string) => {
    return option === "Only Raydium" ? 0.3 * LAMPORTS_PER_SOL : 0.3 * LAMPORTS_PER_SOL;
  };
  const [setLamports, setSetLamports] = useState(getLamports(selectedOption));
  const setViewLamports = setLamports / LAMPORTS_PER_SOL;
  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    setSetLamports(getLamports(option));
  };

  return (
    <>
      <div className="mx-auto max-w-7xl pt-40 px-6" id="exchange-section">
        <div className="table-b bg-navyblue p-8 overflow-x-auto">
          <h3 className="text-white text-2xl">Openbook Market ID</h3>
          <div className="space-y-4">
            <div className="bg-slate-800 border border-slate-700 px-4 py-5 shadow rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-offwhite text-lg font-medium leading-6 text-slate-200">Mints</h3>
                  <p className="text-offwhite mt-1 text-sm">
                    Configure the mints for the tokens you want to create a market for.
                  </p>
                </div>
                <div className="mt-5 space-y-4 md:col-span-2 md:mt-0">
                  <div>
                    <div id="headlessui-radiogroup-:Rpkm:" role="radiogroup">
                      <label className="text-offwhite sr-only" id="headlessui-label-:Rdpkm:">Create Mint</label>
                    </div>
                  </div>
                  <div>
                    <div className="space-y-2">
                      <div>
                        <label className="text-offwhite block text-xs">Base Mint(Only Token Program)</label>
                        <div className="mt-1">
                          <input
                            type="text"
                            className="block w-full rounded-md p-2 bg-slate-700 focus-style sm:text-sm"
                            name="existingMints.baseMint"
                          />
                        </div>
                        <div className="text-offwhite text-left text-[12px] text-slate-500 pb-2">
                          Token2022 does not yet support market creation.
                        </div>
                      </div>
                      <div>
                        <label className="text-offwhite block text-xs">Quote Mint</label>
                        <div className="mt-1">
                          <input
                            type="text"
                            className="block w-full rounded-md p-2 bg-slate-700 focus-style sm:text-sm"
                            name="existingMints.quoteMint"
                            defaultValue="So11111111111111111111111111111111111111112"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-slate-800 border border-slate-700 px-4 py-5 shadow rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="text-offwhite md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-slate-200">Tickers</h3>
                  <p className="mt-1 text-sm">
                    Configure the tick sizes, or lowest representable quantities of base and quote tokens.
                  </p>
                </div>
                <div className="mt-5 space-y-4 md:col-span-2 md:mt-0">
                  <div className="space-y-2">
                    <div>
                      <label className="text-offwhite block text-xs">Min. Order Size</label>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-slate-400 sm:text-sm">1e<sup>-x</sup></span>
                        </div>
                        <input
                          type="number"
                          className="block w-full p-2 rounded-md text-slate-200 bg-slate-700 focus-style pl-16 sm:pl-14 sm:text-sm"
                          name="lotSize"
                        />
                      </div>
                    </div>
                    <div>
                      <label data-tooltip-target="tooltip-default" className="text-offwhite block text-xs">Price Tick</label>
                      <div id="tooltip-default" role="tooltip" className="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700">
                        Tooltip content
                        <div className="tooltip-arrow" data-popper-arrow="true"></div>
                      </div>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <span className="text-slate-400 sm:text-sm">1e<sup>-x</sup></span>
                        </div>
                        <input
                          type="number"
                          className="block w-full p-2 rounded-md text-slate-200 bg-slate-700 focus-style pl-16 sm:pl-14 sm:text-sm"
                          name="tickSize"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-slate-800 border border-slate-700 px-4 py-5 shadow rounded-lg sm:p-6">
              <div className="text-offwhite md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-offwhite text-lg font-medium leading-6 text-slate-200">Advanced Options</h3>
                  <div className="mt-6">
                    <div className="mb-1 flex items-center space-x-1">
                      <p className="text-offwhite text-xs text-slate-300">Total Rent + Fee Estimate</p>
                    </div>
                    <p className="text-lg text-[#AA68FF]">{setViewLamports} SOL</p>
                  </div>
                </div>
                <div className="mt-5 space-y-4 md:col-span-2 md:mt-0">
                  <div className="space-y-3">
                    <div>
                      <div className="mb-4">
                        <label className="text-offwhite block text-xs" id="quick-select-label">Quick Select</label>
                        <div className="flex rounded-md overflow-hidden">
                          <div
                            id="only-raydium"
                            role="radio"
                            aria-checked={selectedOption === "Only Raydium"}
                            tabIndex={selectedOption === "Only Raydium" ? 0 : -1}
                            onClick={() => handleOptionChange("Only Raydium")}
                            className={`py-2 px-4 cursor-pointer text-sm rounded-full mr-1 ${selectedOption === "Only Raydium" ? "bg-[#A06BE5] hover:bg-[#9060CE] text-slate-200" : "bg-slate-700 text-slate-400"}`}
                          >
                            Only Raydium
                          </div>
                          <div
                            id="openbook-raydium"
                            role="radio"
                            aria-checked={selectedOption === "OpenBook + Raydium"}
                            tabIndex={selectedOption === "OpenBook + Raydium" ? 0 : -1}
                            onClick={() => handleOptionChange("OpenBook + Raydium")}
                            className={`py-2 px-4 cursor-pointer text-sm rounded-full mr-1 ${selectedOption === "OpenBook + Raydium" ? "bg-[#A06BE5] hover:bg-[#9060CE] text-slate-200" : "bg-slate-700 text-slate-400"}`}
                          >
                            OpenBook + Raydium
                          </div>
                        </div>
                      </div>
                      {selectedOption === "Only Raydium" && (
                        <div className="text-red text-sm font-bold">
                          The selected option can only be used for Raydium pool creation purposes. Operation in Openbook is not guaranteed.<br />
                        </div>
                      )}
                      <div className="flex items-center justify-between">
                        <span className="flex flex-grow flex-col space-y-0.5">
                          <span className="input-label" id="use-advanced-options-label">Use Advanced Options</span>
                          <span className="text-sm text-slate-500" id="use-advanced-options-description">
                            Set custom sizes for market accounts.<br />
                            Market ID creation uses leased storage space on the Solana network.<br />
                            Most of the fees are rent fees.
                          </span>
                        </span>
                        <button
                          className="bg-slate-400 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus-style"
                          role="switch"
                          type="button"
                          tabIndex={0}
                          aria-labelledby="use-advanced-options-label"
                          aria-describedby="use-advanced-options-description"
                        >
                          <span
                            aria-hidden="true"
                            className="translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          ></span>
                        </button>
                      </div>
                    </div>
                    <div className="opacity-30">
                      <label className="block text-xs text-slate-400">Event Queue Length</label>
                      <div className="mt-1">
                        <div className="relative flex items-center">
                          <input
                            type="number"
                            disabled
                            className="block w-full rounded-md p-2 bg-slate-700 border-gray-300 focus-style sm:text-sm"
                            name="eventQueueLength"
                          />
                          <p className="absolute right-0 mr-2 text-sm text-slate-400">262108 bytes</p>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-30">
                      <label className="block text-xs text-slate-400">Request Queue Length</label>
                      <div className="mt-1">
                        <div className="relative flex items-center">
                          <input
                            type="number"
                            disabled
                            className="block w-full rounded-md p-2 bg-slate-700 border-gray-300 focus-style sm:text-sm"
                            name="requestQueueLength"
                          />
                          <p className="absolute right-0 mr-2 text-sm text-slate-400">5084 bytes</p>
                        </div>
                      </div>
                    </div>
                    <div className="opacity-30">
                      <label className="block text-xs text-slate-400">Orderbook Length</label>
                      <div className="mt-1">
                        <div className="relative flex items-center">
                          <input
                            type="number"
                            disabled
                            className="block w-full rounded-md p-2 bg-slate-700 border-gray-300 focus-style sm:text-sm"
                            name="orderbookLength"
                          />
                          <p className="absolute right-0 mr-2 text-sm text-slate-400">65500 bytes</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between w-[300px]">
                <span className="flex flex-grow flex-col space-y-0.5">
                  <span className="text-offwhite input-label font-bold" id="headlessui-label-:Rb4pkm:">
                    Risk Agreement
                  </span>
                  <span className="text-offwhite text-sm text-slate-500" id="headlessui-description-:Rj4pkm:">
                    I confirm that I have confirmed all details and that I am fully responsible for creating the market.<br />
                    <span className="text-offwhite text-sm text-slate-400">
                      Need at least <span className="text-lg font-bold text-yellow">${setViewLamports} SOL</span> to prevent transaction failure.
                    </span>
                  </span>
                </span>
              </div>
            </div>

            <div className="text-center text-red-100 text-lg">
              <span className="text-sm text-red-100">
                If your $SOL <span className="text-yellow font-bold text-sm">(${setViewLamports} SOL)</span> balance is insufficient,
                your transaction may fail and you may only be charged a fee.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Table;
